import React from 'react'
import { graphql } from 'gatsby'
import intl from 'react-intl-universal'
import { IndustryBanner, InviteBanner } from '../../components/banners'
import { CommonTitle } from '../../components/titles'
import { Swiper, H4 } from '@raysync/common/components'
import { LOCALES } from '@raysync/common/services'
import Layout from '../../components/layout'

import {
  SectionDesc,
  FeatureSection,
  SolutionSection,
  AdvantageSection,
  AdvantageContainer,
  AdvantageItem,
} from './atoms'
import List from '../../components/list'

import Feature1 from '@raysync/common/components/svg/car-feature1'
import Feature2 from '@raysync/common/components/svg/car-feature2'
import Feature3 from '@raysync/common/components/svg/car-feature3'
import Feature4 from '@raysync/common/components/svg/car-feature4'

import Advantage1 from '@raysync/common/components/svg/car-advantage1'
import Advantage2 from '@raysync/common/components/svg/car-advantage2'
import Advantage3 from '@raysync/common/components/svg/car-advantage3'
import Advantage4 from '@raysync/common/components/svg/car-advantage4'

import Solution from '@raysync/common/components/svg/solutions/industry/car-solution'
import SolutionEn from '@raysync/common/components/svg/solutions/industry/car-solution-en'

class CarIndustry extends React.PureComponent {
  state = {
    challengeList: [
      {
        title: intl.get('car.challenge.item1.title'),
        desc: intl.get('car.challenge.item1.desc'),
        svg: <Feature1 />,
      },
      {
        title: intl.get('car.challenge.item2.title'),
        desc: intl.get('car.challenge.item2.desc'),
        svg: <Feature2 />,
      },
      {
        title: intl.get('car.challenge.item3.title'),
        desc: intl.get('car.challenge.item3.desc'),
        svg: <Feature3 />,
      },
      {
        title: intl.get('car.challenge.item4.title'),
        desc: intl.get('car.challenge.item4.desc'),
        svg: <Feature4 />,
      },
    ],
    advantageList: [
      {
        title: intl.get('car.advantage.item1.title'),
        desc: intl.get('car.advantage.item1.desc'),
        svg: <Advantage1 />,
      },
      {
        title: intl.get('car.advantage.item2.title'),
        desc: intl.get('car.advantage.item2.desc'),
        svg: <Advantage2 />,
      },
      {
        title: intl.get('car.advantage.item3.title'),
        desc: intl.get('car.advantage.item3.desc'),
        svg: <Advantage3 />,
      },
      {
        title: intl.get('car.advantage.item4.title'),
        desc: intl.get('car.advantage.item4.desc'),
        svg: <Advantage4 />,
      },
    ],
  }
  render() {
    const { location, data } = this.props
    const { challengeList, advantageList } = this.state
    const solutionPic = {
      [LOCALES.ZH]: <Solution />,
      [LOCALES.EN]: <SolutionEn />,
      [LOCALES.JA]: <SolutionEn />,
    }[intl.options.currentLocale]
    return (
      <Layout pageType='car' location={location}>
        <Swiper>
          <IndustryBanner type='car' data={data.carBanner.childImageSharp.fluid} />
        </Swiper>
        <FeatureSection>
          <CommonTitle bottom={44}>{intl.get('car.challenge.title')}</CommonTitle>
          <List data={challengeList} style={{ minHeight: '170px' }} />
        </FeatureSection>
        <SolutionSection>
          <CommonTitle>{intl.get('car.solution.title')}</CommonTitle>
          <SectionDesc top={21} bottom={43}>
            {intl.get('car.solution.desc')}
          </SectionDesc>
          <div style={{ margin: '0 240px' }}>{solutionPic}</div>
        </SolutionSection>
        <AdvantageSection>
          <CommonTitle>{intl.get('car.advantage.title')}</CommonTitle>
          <AdvantageContainer>
            {advantageList.map((l, i) => (
              <AdvantageItem key={i}>
                <div>{l.svg}</div>
                <H4>{l.title}</H4>
                <p>{l.desc}</p>
              </AdvantageItem>
            ))}
          </AdvantageContainer>
        </AdvantageSection>
        <Swiper>
          <InviteBanner type='car' variant='inquire' />
        </Swiper>
      </Layout>
    )
  }
}

export default CarIndustry

export const query = graphql`
  query {
    carBanner: file(relativePath: { regex: "/industry/car_banner/" }) {
      ...fluidImage
    }
  }
`
