import React from 'react'

const SvgCarAdvantage1 = props => (
  <svg id='car_advantage1_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 50 50' xmlSpace='preserve' {...props}>
    <style>{'.car_advantage1_svg__st0{fill:#334966}'}</style>
    <g id='car_advantage1_svg__\u63A5\u5165\u65B9\u4FBF-48x48'>
      <path
        id='car_advantage1_svg__Combined-Shape_20_'
        className='car_advantage1_svg__st0'
        d='M30 40v-2h17V6H3v32h17v2H1V4h48v36H30z'
      />
      <path id='car_advantage1_svg__Rectangle-17-Copy' className='car_advantage1_svg__st0' d='M13 44h24v2H13z' />
      <path
        id='car_advantage1_svg__Combined-Shape-Copy'
        d='M18 31c-3.87 0-7-3.13-7-7s3.13-7 7-7c.1 0 .19 0 .29.01.86-2.9 3.54-5.01 6.71-5.01 3.87 0 7 3.13 7 7v.08c.33-.05.66-.08 1-.08 3.31 0 6 2.69 6 6s-2.69 6-6 6c-.34 0-.67-.03-1-.08V31H18z'
        fill='#fd5c1f'
      />
      <path
        id='car_advantage1_svg__Combined-Shape_19_'
        className='car_advantage1_svg__st0'
        d='M24 23.27L20.5 27 19 25.4l6-6.4 6 6.4-1.5 1.6-3.5-3.73V46h-2V23.27z'
      />
    </g>
  </svg>
)

export default SvgCarAdvantage1
