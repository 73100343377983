import React from 'react'

const SvgCarSolution = props => (
  <svg
    id='car_solution_svg__\u89E3\u51B3\u65B9\u6848'
    x={0}
    y={0}
    viewBox='0 0 710 470.5'
    xmlSpace='preserve'
    {...props}
  >
    <style>
      {
        '.car_solution_svg__st0{fill-rule:evenodd;clip-rule:evenodd;fill:#fd5c1f}.car_solution_svg__st1{stroke-dasharray:4,2}.car_solution_svg__st1,.car_solution_svg__st2{fill:#fff;stroke:#7b899c}.car_solution_svg__st3{fill:#7b899c}.car_solution_svg__st4{font-family:&apos;SourceHanSansCN-Regular-GBpc-EUC-H&apos;}.car_solution_svg__st5{font-size:14px}.car_solution_svg__st6{fill:#fd5c1f}.car_solution_svg__st7{fill-rule:evenodd;clip-rule:evenodd;fill:#fff}'
      }
    </style>
    <path
      id='car_solution_svg__\u591A\u8FB9\u5F62_2_\u62F7\u8D1D_8'
      className='car_solution_svg__st0'
      d='M356.03 339.5v31h2v-31h-2zm0-24h2V139.49h6.98l-8-9.99-8 9.99h7.02V315.5z'
    />
    <path
      id='car_solution_svg__\u5706\u89D2\u77E9\u5F62_3_\u62F7\u8D1D_3'
      className='car_solution_svg__st1'
      d='M112 162.5h490c2.76 0 5 2.24 5 5v110c0 2.76-2.24 5-5 5H112c-2.76 0-5-2.24-5-5v-110c0-2.76 2.24-5 5-5z'
    />
    <path
      id='car_solution_svg__\u5706\u89D2\u77E9\u5F62_3_\u62F7\u8D1D_4'
      className='car_solution_svg__st1'
      d='M306 .5h110c2.76 0 5 2.24 5 5v110c0 2.76-2.24 5-5 5H306c-2.76 0-5-2.24-5-5V5.5c0-2.76 2.24-5 5-5z'
    />
    <path
      id='car_solution_svg__\u5706\u89D2\u77E9\u5F62_3'
      className='car_solution_svg__st2'
      d='M168 4.5h74c2.76 0 5 2.24 5 5v20c0 2.76-2.24 5-5 5h-74c-2.76 0-5-2.24-5-5v-20c0-2.76 2.24-5 5-5z'
    />
    <path
      id='car_solution_svg__\u5706\u89D2\u77E9\u5F62_3_\u62F7\u8D1D'
      className='car_solution_svg__st2'
      d='M168 45.5h74c2.76 0 5 2.24 5 5v20c0 2.76-2.24 5-5 5h-74c-2.76 0-5-2.24-5-5v-20c0-2.76 2.24-5 5-5z'
    />
    <path
      id='car_solution_svg__\u5706\u89D2\u77E9\u5F62_3_\u62F7\u8D1D_2'
      className='car_solution_svg__st2'
      d='M168 86.5h74c2.76 0 5 2.24 5 5v20c0 2.76-2.24 5-5 5h-74c-2.76 0-5-2.24-5-5v-20c0-2.76 2.24-5 5-5z'
    />
    <text
      id='car_solution_svg__Raysync\u9AD8\u901F\u4F20\u8F93\u670D\u52A1\u5668'
      transform='translate(164 267.5)'
      className='car_solution_svg__st3 car_solution_svg__st4 car_solution_svg__st5'
    >
      {'Raysync\u9AD8\u901F\u4F20\u8F93\u670D\u52A1\u5668'}
    </text>
    <text
      id='car_solution_svg__\u5B58\u50A8\u8BBE\u5907'
      transform='translate(331.996 101.499)'
      className='car_solution_svg__st3 car_solution_svg__st4 car_solution_svg__st5'
    >
      {'\u5B58\u50A8\u8BBE\u5907'}
    </text>
    <path
      id='car_solution_svg__\u77E9\u5F62_82_\u62F7\u8D1D_4'
      className='car_solution_svg__st6'
      d='M655 383.5h50c2.76 0 5 2.24 5 5v50c0 2.76-2.24 5-5 5h-50c-2.76 0-5-2.24-5-5v-50c0-2.76 2.24-5 5-5z'
    />
    <path
      id='car_solution_svg__\u77E9\u5F62_82_\u62F7\u8D1D_5'
      className='car_solution_svg__st6'
      d='M508 383.5h50c2.76 0 5 2.24 5 5v50c0 2.76-2.24 5-5 5h-50c-2.76 0-5-2.24-5-5v-50c0-2.76 2.24-5 5-5z'
    />
    <path
      id='car_solution_svg__\u5F62\u72B6_8'
      className='car_solution_svg__st7'
      d='M518.74 410.5v10.08h-.77V410.5h.77zm29.91 11.3v-10.08h.76v10.08h-.76zm10.35 9.7h-51v-1.53h4.24c-.03-.14-.05-.29-.05-.44v-24.24c0-1.09.88-1.97 1.97-1.97h7.13v1.54h-7.13c-.24 0-.44.19-.44.43v24.24c0 .24.2.44.44.44h9.29c-.04-.14-.06-.29-.06-.44v-32.07c.01-1.08.89-1.96 1.97-1.96h16.01c1.08 0 1.96.88 1.96 1.96v32.07c0 .15-.02.3-.05.44h9.69c.24 0 .43-.2.43-.44v-24.24c0-.24-.19-.43-.42-.43h-7.66v-1.54h7.65c1.09.01 1.96.88 1.97 1.97v24.24c0 .15-.02.3-.05.44H559v1.53zm-17.2-1.97v-32.07c0-.24-.19-.43-.43-.43h-16.01c-.24 0-.43.19-.43.43v32.07c0 .24.19.44.43.44h16.01c.24 0 .43-.2.43-.44zm-3.51-22.35H528.2v-.77h10.09v.77zm0 3.7H528.2v-.77h10.09v.77zm0 3.27H528.2v-.77h10.09v.77z'
    />
    <path
      id='car_solution_svg__\u77E9\u5F62_82_\u62F7\u8D1D_6'
      className='car_solution_svg__st6'
      d='M332 383.5h50c2.76 0 5 2.24 5 5v50c0 2.76-2.24 5-5 5h-50c-2.76 0-5-2.24-5-5v-50c0-2.76 2.24-5 5-5z'
    />
    <path
      id='car_solution_svg__\u5F62\u72B6_8_\u62F7\u8D1D_2'
      className='car_solution_svg__st7'
      d='M342.74 410.5v10.08h-.77V410.5h.77zm29.91 11.3v-10.08h.76v10.08h-.76zm10.35 9.7h-51v-1.53h4.24c-.03-.14-.05-.29-.05-.44v-24.24c0-1.09.88-1.97 1.97-1.97h7.13v1.54h-7.13c-.24 0-.44.19-.44.43v24.24c0 .24.2.44.44.44h9.29c-.04-.14-.06-.29-.06-.44v-32.07c.01-1.08.89-1.96 1.97-1.96h16.01c1.08 0 1.96.88 1.96 1.96v32.07c0 .15-.02.3-.05.44h9.69c.24 0 .43-.2.43-.44v-24.24c0-.24-.19-.43-.42-.43h-7.66v-1.54h7.65c1.09.01 1.96.88 1.97 1.97v24.24c0 .15-.02.3-.05.44H383v1.53zm-17.2-1.97v-32.07c0-.24-.19-.43-.43-.43h-16.01c-.24 0-.43.19-.43.43v32.07c0 .24.19.44.43.44h16.01c.24 0 .43-.2.43-.44zm-3.51-22.35H352.2v-.77h10.09v.77zm0 3.7H352.2v-.77h10.09v.77zm0 3.27H352.2v-.77h10.09v.77z'
    />
    <path
      id='car_solution_svg__\u77E9\u5F62_82_\u62F7\u8D1D_7'
      className='car_solution_svg__st6'
      d='M171 383.5h50c2.76 0 5 2.24 5 5v50c0 2.76-2.24 5-5 5h-50c-2.76 0-5-2.24-5-5v-50c0-2.76 2.24-5 5-5z'
    />
    <path
      id='car_solution_svg__\u5F62\u72B6_8_\u62F7\u8D1D_3'
      className='car_solution_svg__st7'
      d='M180.74 410.5v10.08h-.77V410.5h.77zm29.91 11.3v-10.08h.76v10.08h-.76zm10.35 9.7h-51v-1.53h4.24c-.03-.14-.05-.29-.05-.44v-24.24c0-1.09.88-1.97 1.97-1.97h7.13v1.54h-7.13c-.24 0-.44.19-.44.43v24.24c0 .24.2.44.44.44h9.29c-.04-.14-.06-.29-.06-.44v-32.07c.01-1.08.89-1.96 1.97-1.96h16.01c1.08 0 1.96.88 1.96 1.96v32.07c0 .15-.02.3-.05.44h9.69c.24 0 .43-.2.43-.44v-24.24c0-.24-.19-.43-.42-.43h-7.66v-1.54h7.65c1.09.01 1.96.88 1.97 1.97v24.24c0 .15-.02.3-.05.44H221v1.53zm-17.2-1.97v-32.07c0-.24-.19-.43-.43-.43h-16.01c-.24 0-.43.19-.43.43v32.07c0 .24.19.44.43.44h16.01c.24 0 .43-.2.43-.44zm-3.51-22.35h-10.1v-.77h10.1v.77zm0 3.7h-10.1v-.77h10.1v.77zm0 3.27h-10.1v-.77h10.1v.77z'
    />
    <path
      id='car_solution_svg__\u77E9\u5F62_82_\u62F7\u8D1D_8'
      className='car_solution_svg__st6'
      d='M16 383.5h50c2.76 0 5 2.24 5 5v50c0 2.76-2.24 5-5 5H16c-2.76 0-5-2.24-5-5v-50c0-2.76 2.24-5 5-5z'
    />
    <path
      id='car_solution_svg__\u5F62\u72B6_8_\u62F7\u8D1D_4'
      className='car_solution_svg__st7'
      d='M26.74 410.5v10.08h-.77V410.5h.77zm29.91 11.3v-10.08h.77v10.08h-.77zM67 431.5H16v-1.53h4.24c-.03-.14-.05-.29-.05-.44v-24.24c0-1.09.88-1.97 1.97-1.97h7.13v1.54h-7.13c-.24 0-.43.19-.43.43v24.24c0 .24.19.44.43.44h9.29c-.03-.14-.05-.29-.05-.44v-32.07c0-1.08.88-1.96 1.97-1.96h16c1.08 0 1.96.88 1.97 1.96v32.07c0 .15-.02.3-.05.44h9.69c.24 0 .43-.2.43-.44v-24.24c0-.24-.19-.43-.43-.43h-7.65v-1.54h7.65c1.09 0 1.97.88 1.97 1.97v24.24c0 .15-.02.3-.05.44H67v1.53zm-17.2-1.97v-32.07c0-.24-.2-.43-.43-.43h-16c-.24 0-.43.19-.43.43v32.07c0 .24.19.44.43.44h16c.24 0 .43-.2.43-.44zm-3.51-22.35H36.2v-.77h10.1l-.01.77zm0 3.7H36.2v-.77h10.1l-.01.77zm0 3.27H36.2v-.77h10.1l-.01.77z'
    />
    <text
      id='car_solution_svg__\u56FD\u5916\u7814\u53D1\u4E2D\u5FC3'
      transform='translate(-1 468.5)'
      className='car_solution_svg__st3 car_solution_svg__st4 car_solution_svg__st5'
    >
      {'\u56FD\u5916\u7814\u53D1\u4E2D\u5FC3'}
    </text>
    <text
      id='car_solution_svg__\u8BBE\u8BA1\u4E2D\u5FC3'
      transform='translate(167 467.5)'
      className='car_solution_svg__st3 car_solution_svg__st4 car_solution_svg__st5'
    >
      {'\u8BBE\u8BA1\u4E2D\u5FC3'}
    </text>
    <text
      id='car_solution_svg__\u4F9B\u5E94\u5546'
      transform='translate(336.001 467.5)'
      className='car_solution_svg__st3 car_solution_svg__st4 car_solution_svg__st5'
    >
      {'\u4F9B\u5E94\u5546'}
    </text>
    <text
      id='car_solution_svg__\u9500\u552E\u516C\u53F8_4S\u5E97'
      transform='translate(488.19 467.5)'
      className='car_solution_svg__st3 car_solution_svg__st4 car_solution_svg__st5'
    >
      {'\u9500\u552E\u516C\u53F8/4S\u5E97'}
    </text>
    <text
      id='car_solution_svg__\u5408\u4F5C\u4F19\u4F34'
      transform='translate(653.002 467.5)'
      className='car_solution_svg__st3 car_solution_svg__st4 car_solution_svg__st5'
    >
      {'\u5408\u4F5C\u4F19\u4F34'}
    </text>
    <text
      id='car_solution_svg__\u7814\u53D1\u6570\u636E'
      transform='translate(11.999 332.499)'
      className='car_solution_svg__st3 car_solution_svg__st4 car_solution_svg__st5'
    >
      {'\u7814\u53D1\u6570\u636E'}
    </text>
    <text
      id='car_solution_svg__\u8BBE\u8BA1\u8D44\u6599'
      transform='translate(168 332.499)'
      className='car_solution_svg__st3 car_solution_svg__st4 car_solution_svg__st5'
    >
      {'\u8BBE\u8BA1\u8D44\u6599'}
    </text>
    <text
      id='car_solution_svg__\u5408\u4F5C\u8D44\u6599'
      transform='translate(326.999 332.499)'
      className='car_solution_svg__st3 car_solution_svg__st4 car_solution_svg__st5'
    >
      {'\u5408\u4F5C\u8D44\u6599'}
    </text>
    <text
      id='car_solution_svg__\u9500\u552E\u6570\u636E_\u5BA3\u4F20\u8D44\u6599'
      transform='translate(474.25 332.499)'
      className='car_solution_svg__st3 car_solution_svg__st4 car_solution_svg__st5'
    >
      {'\u9500\u552E\u6570\u636E/\u5BA3\u4F20\u8D44\u6599'}
    </text>
    <text
      id='car_solution_svg__\u6570\u636E\u5171\u4EAB'
      transform='translate(650.002 332.499)'
      className='car_solution_svg__st3 car_solution_svg__st4 car_solution_svg__st5'
    >
      {'\u6570\u636E\u5171\u4EAB'}
    </text>
    <path
      id='car_solution_svg__\u77E9\u5F62_81'
      className='car_solution_svg__st6'
      d='M208 182.5h50c2.76 0 5 2.24 5 5v50c0 2.76-2.24 5-5 5h-50c-2.76 0-5-2.24-5-5v-50c0-2.76 2.24-5 5-5z'
    />
    <path
      id='car_solution_svg__\u77E9\u5F62_81_\u62F7\u8D1D'
      className='car_solution_svg__st6'
      d='M453 182.5h50c2.76 0 5 2.24 5 5v50c0 2.76-2.24 5-5 5h-50c-2.76 0-5-2.24-5-5v-50c0-2.76 2.24-5 5-5z'
    />
    <path
      id='car_solution_svg__\u77E9\u5F62_81_\u62F7\u8D1D_2'
      className='car_solution_svg__st6'
      d='M336 20.5h50c2.76 0 5 2.24 5 5v50c0 2.76-2.24 5-5 5h-50c-2.76 0-5-2.24-5-5v-50c0-2.76 2.24-5 5-5z'
    />
    <path
      id='car_solution_svg__\u5F62\u72B6_4'
      className='car_solution_svg__st7'
      d='M492.61 201.82l2.16-1.39-.8-2-2.49.58c-.36-.5-.79-.94-1.28-1.32l.56-2.53-1.96-.83-1.34 2.19c-.61-.1-1.22-.1-1.83-.01l-1.33-2.19-1.97.81.54 2.52c-.5.37-.93.82-1.3 1.32l-2.46-.59-.82 1.99 2.13 1.39c-.1.62-.1 1.25-.01 1.87l-2.15 1.35.8 2 2.48-.55c.36.5.8.95 1.3 1.32l-.59 2.51 1.96.83 1.38-2.18c.61.09 1.22.09 1.83 0l1.36 2.2 1.96-.82-.57-2.53c.49-.37.92-.8 1.28-1.3l2.5.57.82-1.99-2.17-1.38c.09-.61.09-1.23.01-1.84zm-3.36 2.06a2.952 2.952 0 01-3.83 1.64c-.01-.01-.03-.01-.04-.02a3.038 3.038 0 01-1.59-3.94c.6-1.51 2.31-2.24 3.82-1.64.02.01.03.01.05.02 1.51.66 2.22 2.41 1.59 3.94zm4.6 18.67l1.81-1.16-.68-1.67-2.07.49a4.9 4.9 0 00-1.08-1.1l.48-2.12-1.64-.69-1.12 1.83c-.51-.09-1.02-.09-1.53-.01l-1.11-1.83-1.64.68.45 2.1c-.42.31-.79.68-1.09 1.1l-2.06-.5-.68 1.67 1.78 1.16c-.08.52-.08 1.04-.01 1.56l-1.79 1.13.67 1.67 2.07-.46c.3.42.67.79 1.08 1.1l-.49 2.1 1.64.69 1.15-1.82c.5.08 1.02.08 1.52 0l1.14 1.84 1.64-.69-.48-2.11c.41-.31.77-.67 1.07-1.09l2.09.48.68-1.66-1.81-1.15c.08-.51.08-1.03.01-1.54zm-2.8 1.72a2.47 2.47 0 01-3.2 1.37c-.01-.01-.02-.01-.04-.02a2.543 2.543 0 01-1.33-3.29 2.475 2.475 0 013.22-1.36c.01 0 .01.01.02.01 1.26.55 1.85 2.01 1.33 3.29zm-7.18-6.29v-2.82l-3.3-.55a8.18 8.18 0 00-.91-2.23l1.93-2.79-1.97-1.99-2.7 1.98a7.42 7.42 0 00-2.21-.93l-.57-3.35h-2.78l-.53 3.34c-.78.19-1.53.5-2.21.92l-2.7-1.96-1.97 1.99 1.9 2.76c-.41.7-.72 1.47-.92 2.26l-3.26.55v2.82l3.26.59c.2.79.51 1.55.92 2.25l-1.93 2.74 1.97 2 2.72-1.94c.68.42 1.43.73 2.21.93l.54 3.33h2.78l.58-3.34c.78-.2 1.52-.51 2.2-.94l2.74 1.96 1.96-2-1.95-2.76c.41-.69.71-1.45.9-2.23l3.3-.59zm-11.1 2.55c-2.17.04-3.96-1.7-4-3.87a3.933 3.933 0 013.87-4c2.17-.04 3.96 1.7 4 3.87v.06a3.905 3.905 0 01-3.86 3.94h-.01z'
    />
    <path
      id='car_solution_svg__\u5F62\u72B6_7'
      className='car_solution_svg__st7'
      d='M376.17 61.75v-22.5h-5.5v-1.88H378v26.25h-7.33v-1.87h5.5zm-29.34 0v-22.5h5.5v-1.88H345v26.25h7.33v-1.87h-5.5zm7.34-26.25h14.66v30h-14.66v-30zm1.83 1.87v26.25h11V37.37h-11zm3.67 3.75h3.66V43h-3.66v-1.88zm0 7.5h3.66v1.88h-3.66v-1.88zm0 7.5h3.66V58h-3.66v-1.88z'
    />
    <path
      id='car_solution_svg__\u77E9\u5F62_82_\u62F7\u8D1D'
      className='car_solution_svg__st0'
      d='M40 316.5v-92h2v92h-2zm0 24h2v26.01h6.98l-8 9.99-8-9.99H40V340.5zm0-117h58v2H40v-2zm53.01-.03v-6.97l9.99 7.99-9.99 8v-7.02'
    />
    <path
      id='car_solution_svg__\u77E9\u5F62_82_\u62F7\u8D1D_2'
      className='car_solution_svg__st0'
      d='M680.97 316.5v-92h-2v92h2zm0 24h-2v26.01h-6.98l8 9.99 8-9.99h-7.02V340.5zM623 223.5h57.97v2H623v-2zm4.99-.03v-6.97l-9.99 7.99 9.99 8v-7.02'
    />
    <path
      id='car_solution_svg__\u591A\u8FB9\u5F62_2_\u62F7\u8D1D_10'
      className='car_solution_svg__st0'
      d='M337.03 129.5h2v16.01h6.98l-8 9.99-8-9.99h7.02V129.5z'
    />
    <path
      id='car_solution_svg__\u591A\u8FB9\u5F62_2_\u62F7\u8D1D_4'
      className='car_solution_svg__st0'
      d='M532 315.5v-26h2v26h-2zm0 24h2v21.01h6.98l-8 9.99-8-9.99H532V339.5z'
    />
    <path
      id='car_solution_svg__\u591A\u8FB9\u5F62_2_\u62F7\u8D1D_9'
      className='car_solution_svg__st0'
      d='M195 313.5v-24h2v24h-2zm0 24h2v23.01h6.98l-8 9.99-8-9.99H195V337.5z'
    />
    <path
      id='car_solution_svg__\u591A\u8FB9\u5F62_2_\u62F7\u8D1D_5'
      className='car_solution_svg__st0'
      d='M263 18.53h20v2h-20v-2zm-9 .98l9.99-8v16l-9.99-8zm39 0l-9.99-8v16l9.99-8z'
    />
    <path
      id='car_solution_svg__\u591A\u8FB9\u5F62_2_\u62F7\u8D1D_7'
      className='car_solution_svg__st0'
      d='M263 100.53h20v2h-20v-2zm-9 .98l9.99-8v16l-9.99-8zm39 0l-9.99-8v16l9.99-8z'
    />
    <path
      id='car_solution_svg__\u591A\u8FB9\u5F62_2_\u62F7\u8D1D_6'
      className='car_solution_svg__st0'
      d='M263 59.53h20v2h-20v-2zm-9 .98l9.99-8v16l-9.99-8zm39 0l-9.99-8v16l9.99-8z'
    />
    <text
      id='car_solution_svg__ERP_\u7CFB\u7EDF'
      transform='translate(179 24.499)'
      className='car_solution_svg__st3 car_solution_svg__st4 car_solution_svg__st5'
    >
      {'ERP \u7CFB\u7EDF'}
    </text>
    <text
      id='car_solution_svg__SCM_\u7CFB\u7EDF'
      transform='translate(177 65.499)'
      className='car_solution_svg__st3 car_solution_svg__st4 car_solution_svg__st5'
    >
      {'SCM \u7CFB\u7EDF'}
    </text>
    <text
      id='car_solution_svg__CRM_\u7CFB\u7EDF'
      transform='translate(176.001 106.499)'
      className='car_solution_svg__st3 car_solution_svg__st4 car_solution_svg__st5'
    >
      {'CRM \u7CFB\u7EDF'}
    </text>
    <text
      id='car_solution_svg__Raysync\u9AD8\u901F\u4F20\u8F93\u5F15\u64CE'
      transform='translate(409.23 267.5)'
      className='car_solution_svg__st3 car_solution_svg__st4 car_solution_svg__st5'
    >
      {'Raysync\u9AD8\u901F\u4F20\u8F93\u5F15\u64CE'}
    </text>
    <path
      className='car_solution_svg__st7'
      d='M689.95 408.02v-.13c-.07-4.79-4.01-8.61-8.8-8.54-4.79.07-8.61 4.01-8.54 8.8.05 3.25 1.88 6.04 4.54 7.49-5.06 1.86-8.65 6.72-8.62 12.42 0 .09 0 .18.01.27l.01.11c.01.5.42.91.92.91h.03c.51-.02.91-.46.89-.97 0-.06 0-.12-.01-.18v-.14c-.04-6.23 4.97-11.31 11.2-11.37.02 0 .05-.01.07-.01 4.64-.18 8.33-3.99 8.3-8.66zm-15.43-.1a6.801 6.801 0 016.9-6.7c3.73.03 6.73 3.07 6.7 6.8v.1c-.06 3.76-3.14 6.75-6.9 6.7s-6.76-3.15-6.7-6.9z'
    />
    <path
      className='car_solution_svg__st7'
      d='M672.82 416.46c0-.52-.42-.94-.94-.94-.09 0-.18.01-.27.02-.03 0-.05-.02-.08-.02a6.106 6.106 0 01-5.85-5.85 6.089 6.089 0 015.85-6.34.935.935 0 000-1.87 7.972 7.972 0 00-3.79 14.91 11.046 11.046 0 00-6.82 10.28c0 .51.41.93.92.94.51-.01.92-.43.92-.94-.03-5.07 4.06-9.21 9.13-9.25.51 0 .93-.42.93-.94zM695.08 416.36a7.972 7.972 0 00-3.79-14.91.935.935 0 000 1.87c3.17.13 5.72 2.67 5.85 5.85a6.089 6.089 0 01-5.85 6.34c-.03 0-.05.01-.08.02-.09 0-.17-.01-.26-.02a.939.939 0 100 1.88c5.07.04 9.15 4.18 9.12 9.25 0 .51.41.93.92.94.52 0 .93-.42.93-.94.02-4.62-2.81-8.6-6.84-10.28zM688.09 416.58l-.01-.01a.922.922 0 00-1.26.35c-.25.45-.1 1.02.34 1.28a11.44 11.44 0 015.62 9.86v.13c-.01.07-.01.13-.01.2v.01c-.02.51.38.93.89.95h.03c.5 0 .91-.41.92-.91 0-.04.01-.08.01-.12.01-.09.01-.17.01-.26.01-4.72-2.48-9.08-6.54-11.48z'
    />
    <g>
      <path
        className='car_solution_svg__st7'
        d='M252.28 194.73h-36.69c-1.9 0-3.44 1.55-3.44 3.45v25.31c0 1.9 1.54 3.45 3.44 3.46h36.69c1.9-.01 3.44-1.56 3.44-3.46v-25.31-.01c0-1.89-1.54-3.44-3.44-3.44zm1.15 28.76c0 .64-.51 1.15-1.15 1.15h-36.69c-.64 0-1.15-.51-1.15-1.15v-25.31c0-.64.51-1.15 1.15-1.15h36.69c.64 0 1.15.51 1.15 1.15v25.31zM221 229.87c-.04 0-.07.03-.07.07v2.47c0 .04.03.07.07.07h25.86c.04 0 .07-.03.07-.07v-2.47c0-.04-.03-.07-.07-.07H221z'
      />
      <path
        className='car_solution_svg__st7'
        d='M244.46 212.91c-.57 0-1.12.12-1.63.32l-5.01-7.94c.44-.71.71-1.54.71-2.44v-.02a4.605 4.605 0 00-4.63-4.59c-2.55.01-4.6 2.08-4.59 4.63 0 1.09.4 2.08 1.04 2.87l-4.72 7.48c-.52-.2-1.08-.32-1.67-.32-2.55.01-4.6 2.08-4.59 4.63s2.08 4.6 4.63 4.59a4.6 4.6 0 004.43-3.46h11.59c.52 1.99 2.32 3.47 4.47 3.46a4.607 4.607 0 004.59-4.61v-.02c0-2.54-2.07-4.59-4.62-4.58zm-16.95 1.67l4.69-7.45a4.451 4.451 0 003.91-.24l4.84 7.68c-.43.52-.75 1.12-.93 1.8h-11.6c-.15-.67-.48-1.28-.91-1.79zm6.44-14.03a2.3 2.3 0 110 4.6 2.3 2.3 0 010-4.6zm-9.94 19.27a2.3 2.3 0 010-4.6 2.3 2.3 0 010 4.6zm20.49 0a2.3 2.3 0 010-4.6 2.3 2.3 0 010 4.6z'
      />
    </g>
  </svg>
)

export default SvgCarSolution
