import React from 'react'

const SvgCarAdvantage3 = props => (
  <svg id='car_advantage3_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 50 50' xmlSpace='preserve' {...props}>
    <style>{'.car_advantage3_svg__st0{fill:#fd5c1f}.car_advantage3_svg__st1{fill:#334966}'}</style>
    <path id='car_advantage3_svg__Fill-1_7_' className='car_advantage3_svg__st0' d='M24.54 47V10.89l-10 5.28V47z' />
    <path
      id='car_advantage3_svg__Fill-2_1_'
      className='car_advantage3_svg__st1'
      d='M26.54 1l-25 12v36h25V1zm-23 13.26l21-10.08V47h-21V14.26z'
    />
    <path id='car_advantage3_svg__Fill-3_6_' className='car_advantage3_svg__st1' d='M43.54 9h-14v2h12v9h2z' />
    <path id='car_advantage3_svg__Fill-4_1_' className='car_advantage3_svg__st1' d='M12.54 19h8v-2h-8z' />
    <path id='car_advantage3_svg__Fill-5_5_' className='car_advantage3_svg__st1' d='M9.54 29h11v-2h-11z' />
    <path id='car_advantage3_svg__Fill-6_1_' className='car_advantage3_svg__st1' d='M9.54 39h11v-2h-11z' />
    <path
      id='car_advantage3_svg__Fill-7_3_'
      className='car_advantage3_svg__st0'
      d='M44.22 27.97c0 3.19-2.6 5.78-5.8 5.78s-5.8-2.59-5.8-5.78 2.6-5.78 5.8-5.78 5.8 2.59 5.8 5.78'
    />
    <path
      id='car_advantage3_svg__Fill-9_1_'
      className='car_advantage3_svg__st1'
      d='M38.42 35c-5.55 0-10.05 5.78-10.05 12.9 0 .37.02.74.04 1.1h20.01c.02-.36.04-.73.04-1.1 0-7.12-4.5-12.9-10.04-12.9m0 2c4.21 0 7.67 4.41 8.01 10H30.41c.33-5.59 3.8-10 8.01-10'
    />
  </svg>
)

export default SvgCarAdvantage3
