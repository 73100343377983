import React from 'react'

const SvgCarAdvantage2 = props => (
  <svg id='car_advantage2_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 50 50' xmlSpace='preserve' {...props}>
    <style>{'.car_advantage2_svg__st0{fill:#334966}.car_advantage2_svg__st1{fill:#fd5c1f}'}</style>
    <g id='car_advantage2_svg__\u62A4\u822A\u670D\u52A1-48x48'>
      <g id='car_advantage2_svg__Group-15' transform='translate(0 2)'>
        <path id='car_advantage2_svg__Fill-1_4_' className='car_advantage2_svg__st0' d='M1 1v36h22v-2H3V3h44v13h2V1z' />
        <path id='car_advantage2_svg__Fill-2_8_' className='car_advantage2_svg__st0' d='M13 43h10v-2H13z' />
        <path id='car_advantage2_svg__Fill-3_5_' className='car_advantage2_svg__st0' d='M21 43h2v-8h-2z' />
        <path id='car_advantage2_svg__Fill-4_6_' className='car_advantage2_svg__st1' d='M9 13h12v-2H9z' />
        <path id='car_advantage2_svg__Fill-5_4_' className='car_advantage2_svg__st1' d='M9 19h12v-2H9z' />
        <path id='car_advantage2_svg__Fill-6_4_' className='car_advantage2_svg__st1' d='M9 25h12v-2H9z' />
        <path
          id='car_advantage2_svg__Fill-7_1_'
          className='car_advantage2_svg__st1'
          d='M37 22l-1.04.54A24.163 24.163 0 0127 25.21v9.87c0 2.39 1.38 4.58 3.57 5.68L37 44l6.43-3.24c2.2-1.11 3.57-3.3 3.57-5.68v-9.87c-3.14-.3-6.19-1.21-8.96-2.66L37 22z'
        />
        <path
          id='car_advantage2_svg__Path_2_'
          d='M37 17l-1.14.69c-3.05 1.85-6.4 3.01-9.86 3.39v12.56c0 3.04 1.51 5.83 3.93 7.23L37 45l7.07-4.12c2.42-1.41 3.93-4.2 3.93-7.23V21.08c-3.46-.39-6.81-1.54-9.86-3.39L37 17z'
          fill='none'
          stroke='#334966'
          strokeWidth={2}
        />
        <path id='car_advantage2_svg__Fill-11' className='car_advantage2_svg__st0' d='M32 33h10v-2H32z' />
        <path id='car_advantage2_svg__Fill-13' className='car_advantage2_svg__st0' d='M36 37h2V27h-2z' />
      </g>
    </g>
  </svg>
)

export default SvgCarAdvantage2
